/* eslint-disable import/no-unresolved */
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Nav, NavItem, NavLink, NavbarBrand, NavbarToggler, Media, Navbar, Collapse, Container,
} from 'reactstrap';
import logo from 'images/agrobikes.svg';
import logoSM from 'images/agrobikes-iso.svg';
import logoSU from 'images/logo-suzuki-agronomia.svg';
import logoSUsvg from 'images/suzuki-logo-full.svg';
import logoWAY from 'images/suzuki-way.png';
import wp from 'images/whatsapp.svg';
import menu from 'images/menu.svg';
import arrow from 'images/arrow-w.svg'; 
import { Link } from 'react-scroll';
import "../../assets/styles.css"
const refreshPage = () => {
  location.href = "/";  
}

const HeaderBrand = (props) => {

  const [isOpen, setIsOpen] = useState(false);
  const { brandSlug, logoBrand } = props;

  const toggle = () => setIsOpen(!isOpen);
  const close = () => setIsOpen(false);

  return (
    <Navbar expand="lg" className="w-100 px-0 py-0">
      <Container className="py-0 px-0" fluid="lg">
        <NavbarBrand onClick={refreshPage} className="py-0">
          {/* <Media className="d-inline-block d-md-none w-50 logo-menu" src={logoSM} alt="Agrobikes" width="100" height="100" /> */}
          {/* </NavbarBrand> */}
          {/* <NavbarBrand href={`/${brandSlug}`} className="py-0"> */}
          {/* <Media className="d-md-inline-block d-none w-50 logo-menu" src={logoWAY} alt="Agrobikes" /> */}
          {/* <Media className="w-75 logo-menu" src={logoBrand} alt={brandSlug} width="100" height="100" /> */}
          {/* <Media className="d-md-inline-block d-none w-100 logo-menu" src={logoSU} alt="Agrobikes" width="100" height="100" /> */}
          <Media className="d-inline-block d-none w-100 logo-menu" src={logoSU} alt="Agrobikes" width="100" height="100" />
        </NavbarBrand>
        <NavbarToggler onClick={toggle} className="order-3">
          <Media src={menu} alt="Agrobikes" className="menu-img" width="100" height="100" />
        </NavbarToggler>
        <Collapse isOpen={isOpen} navbar className="order-4 order-lg-0 borde">
          <Nav className="ml-auto" navbar>
            <ItemHeader redirectTo="/" title="Inicio" close={close} />
            {/* <ItemHeader redirectTo={`/${brandSlug}/compania`} title="COMPAÑÍA" close={close} /> */}
            <ItemHeader to="form-service" title="Servicio" close={close} />
            <ItemHeader to="insurance" title="Seguros" close={close} />
            <ItemHeader to="accessory" title="Shop" close={close} />
            <ItemHeader to="form_about" title="Contacto" close={close} />
            {/* <ItemHeader redirectTo="/" title={`SALIR DE ${brandSlug}`} hideDots close={close} /> */}
          </Nav>
        </Collapse>
        <NavLink className="order-lg-2 ml-auto container_wpp" href="https://api.whatsapp.com/send?phone=5491125090999" target="_blank" rel="noopener">
          <Media src={wp} alt="Agrobikes" className="menu-img wpp" width="100" height="100" />
        </NavLink>
      </Container>
    </Navbar>
  );
};

const ItemHeader = (props) => {
  const {
    title, hideDots, to, close, redirectTo,
  } = props;
  return (
    <NavItem className="pr-2 text-nowrap w-100 text-center">
      {to && (
      <Fragment>
        {/* <i className="fas fa-chevron-right text-white" ></i>  */}
        <Link
          className="d-inline-block w-100 nav-link position-relative pr-0 text-black"
          to={to}
          spy
          smooth
          offset={-70}
          duration={1500}
          onClick={close}
          isDynamic
          >
          {title}
          {/* {<Media src={arrow} className="d-lg-none d-inline-block position-absolute" width="100" height="100" /> } */}
        </Link>
      </Fragment>
      )}
      {redirectTo && (
        <Fragment>
          {/* <i className="fas fa-chevron-right text-white derecha" ></i> */}
          <NavLink
            className="d-inline-block w-100 nav-link position-relative pr-0 text-black"
            onClick={refreshPage}
            //href={redirectTo}
          >
            {title}
            {/* <Media src={arrow} className="d-lg-none d-inline-block position-absolute" width="100" height="100" /> */}
          </NavLink>
        </Fragment>
      )}
      { !hideDots
        ? (
          <span className="navbar-separator d-lg-inline-block d-none">
            <span />
            <span />
            <span />
          </span>
        )
        : ''}
    </NavItem>
  );
};

ItemHeader.propTypes = {
  title: PropTypes.string.isRequired,
  hideDots: PropTypes.bool,
  to: PropTypes.string,
  redirectTo: PropTypes.string,
  close: PropTypes.func,
};

ItemHeader.defaultProps = {
  hideDots: false,
  to: '',
  redirectTo: '',
  close: null,
};

HeaderBrand.propTypes = {
  brandSlug: PropTypes.string,
  logoBrand: PropTypes.string,
};

HeaderBrand.defaultProps = {
  brandSlug: '',
  logoBrand: '',
};

export default HeaderBrand;
