import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import {
  Container, Media, NavLink,
} from 'reactstrap';
import LazyLoad from 'react-lazyload';
import createMarkup from './Utils';
import SeparatorH from './SeparatorH';
import Player from './Player';

function redirectPage(product) {
  location.href = product;
}

const BannerCarousel = ({ items, color, className }) => {
  const [playing, setPlaying] = useState('');

  const onPlay = (youtubeId) => {
    setPlaying(youtubeId);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    autoplaySpeed: 6000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    lazyLoad: false,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          dots: false,
        },
      },
    ],
    beforeChange(currentSlide, nextSlide) {
      const item = items[nextSlide];
      setPlaying(item.autoplay ? item.youtube_id : '');
    },
  };

  return (
  // eslint-disable-next-line react/jsx-props-no-spreading
    // <LazyLoad once offset={200}>
      <Slider {...settings} className={`mb-md-0 mt-0 banner ${className}`}>
        {items && items.map((item) => (
          <div key={item.id} className="px-0 py-0 position-relative">
            <div className="max-vh-100 overflow-hidden">
              {item.picture
                && <Media object src={item.picture.md || ''} /*style={{backgroundImage: "url(" + item.picture.md + ")"}}*/ alt={item.title} className="w-100" width="100" height="100" />}
            </div>
            {item.youtube_id
                && (
                  <Player
                    youtubeId={item.youtube_id}
                    playing={playing}
                    color={item.brand ? item.brand.color : color}
                    onPlay={() => onPlay(item.youtube_id)}
                  />
                )}
            <div className={`caption ${playing ? 'hide' : ''}`}>
              <Container className="d-flex flex-column justify-content-center h-100">
                <div className="my-4 d-none d-md-block description">
                  {item.subtitle && (
                    <Media tag="h4" className="mb-0 mt-4 ">
                      {item.subtitle}
                    </Media>
                  )}
                  {item.title && (
                    <div>
                      <Media tag="h1" className="mt-3 title">
                        {item.title}
                      </Media>
                      <SeparatorH color={item.brand ? item.brand.color : color} />
                    </div>
                  )}
                  {item.description
                    && (
                      <span dangerouslySetInnerHTML={createMarkup(item.description)} className="description-html mb-3 d-block" style={{width: '300px'}} />
                    )}
                  {item.link && <NavLink onClick={()=> redirectPage(item.link)} /* href={item.link} */ className="link_to text-white px-3 py-2 mt-4 d-inline-block wow fadeInUp show">Ver más</NavLink>}
                </div>
              </Container>
            </div>
            <div className="mt-0 pt-0 d-block d-md-none bg-black position-relative">
              {item.subtitle
                  && (
                  <Media tag="h6" className="mb-0 mt-0 py-2 wow fadeInUp font-weight-black" style={{ backgroundColor: item.brand ? item.brand.color : color }}>
                    <Container className="container my-1">{item.subtitle}</Container>
                  </Media>
                  )}
              {item.link
                    && <NavLink href={item.link} className="link_to text-white px-3 py-2 d-inline-block wow fadeInUp show">Ver más</NavLink>}
              {item.title
                    && (
                    <Media tag="h5" className="mb-0 mt-0 py-2 wow fadeInUp bg-white text-dark">
                      <Container className="container my-1">{item.title}</Container>
                    </Media>
                    )}
              {item.description
                    && (
                      <Container dangerouslySetInnerHTML={createMarkup(item.description)} className="description-html mb-0 py-3 bg-black" />
                    )}
            </div>
          </div>
        ))}
      </Slider>
    // </LazyLoad>
  );
};

BannerCarousel.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  items: PropTypes.array.isRequired,
  color: PropTypes.string,
  className: PropTypes.string,
};

BannerCarousel.defaultProps = {
  color: '',
  className: '',
};

export default BannerCarousel;
