import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Nav, NavItem, NavLink, NavbarBrand, NavbarToggler, Media, Navbar, Collapse, Container,
} from 'reactstrap';
import logoSU from 'images/logo-suzuki-agronomia.svg';
import wp from 'images/whatsapp.svg';
import menu from 'images/menu.svg';
import arrow from 'images/arrow-w.svg'; 
import { Link } from 'react-scroll';

const HeaderNew = () => {
    const [isOpen, setIsOpen] = useState(false);
    //const { brandSlug, logoBrand } = props;

    const toggle = () => setIsOpen(!isOpen);
    const close = () => setIsOpen(false);

    return (
        <Navbar expand="lg" className="w-100 px-0 py-0">
            <Container className="py-0 px-0">
                <NavbarBrand href="/" className="py-0">
                <Media className="d-inline-block d-none w-100 logo-menu" src={logoSU} alt="Agrobikes" width="100" height="100" />
                </NavbarBrand>
                <NavbarToggler onClick={toggle} className="order-3">
                <Media src={menu} alt="Agrobikes" className="menu-img" width="100" height="100" />
                </NavbarToggler>
                <Collapse isOpen={isOpen} navbar className="order-4 order-lg-0">
                <Nav className="ml-auto text-center" navbar>
                    <ItemHeader redirectTo="/" title="INICIO" close={close} />
                    <ItemHeader to="form-service" title="SERVICIO" close={close} />
                    <ItemHeader to="insurance" title="SEGUROS" close={close} />
                    <ItemHeader to="accessory" title="SHOP" close={close} />
                    <ItemHeader to="form_about" title="CONTACTO" close={close} />
                </Nav>
                </Collapse>
                <NavLink className="order-lg-2 ml-auto" href="https://api.whatsapp.com/send?phone=5491125084777" target="_blank" rel="noopener">
                <Media src={wp} alt="Agrobikes" className="menu-img" width="100" height="100" />
                </NavLink>
            </Container>
        </Navbar>
    )
}

const ItemHeader = (props) => {
    const {
      title, hideDots, to, close, redirectTo,
    } = props;
    return (
      <NavItem className="pr-2 text-nowrap w-100">
        {to && (
        <Link
          className="d-inline-block w-100 nav-link position-relative pr-0 text-black"
          to={to}
          spy
          smooth
          offset={-70}
          duration={1500}
          onClick={close}
          isDynamic
        >
          {title}
          <Media src={arrow} className="d-lg-none d-inline-block position-absolute" width="100" height="100" />
        </Link>
        )}
        {redirectTo && (
          <NavLink
            className="d-inline-block w-100 nav-link position-relative text-uppercase pr-1 text-black"
            href={redirectTo}
          >
            {title}
            <Media src={arrow} className="d-lg-none d-inline-block position-absolute" width="100" height="100" />
          </NavLink>
        )}
        { !hideDots
          ? (
            <span className="navbar-separator d-lg-inline-block d-none">
              <span />
              <span />
              <span />
            </span>
          )
          : ''}
      </NavItem>
    );
  };



export default HeaderNew