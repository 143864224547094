import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Col, Container, Row,
} from 'reactstrap';
import LazyLoad from 'react-lazyload';
import DataBrand from './DataBrand';
import SendButton from './SendButton';
import ProductCarousel from './ProductCarousel';

// eslint-disable-next-line react/prefer-stateless-function
class BrandContainer extends Component {
  render() {
    const { items } = this.props;
    return (
      <LazyLoad once offset={200}>
        {items.map((brand) => (
          <div key={brand.slug}>
            <Container className="mb-0">
              <DataBrand
                title={brand.title}
                color={brand.color}
                description={brand.description}
                logo={brand.logo.md}
                name={brand.name}
                key={brand.slug}
              />
            </Container>
            {brand.show_products_to_json && (
              <div>
                <ProductCarousel items={brand.show_products_to_json} brand={brand.slug} className={'my-4'}/>
                {brand.slug && (
                  <Row className="mx-0 my-5">
                    <Col
                      xs={12}
                      sm={{ size: 10, offset: 1 }}
                      md={{ size: 8, offset: 2 }}
                      lg={{ size: 6, offset: 3 }}
                      xl={{ size: 4, offset: 4 }}
                    >
                      <SendButton text={`INGRESAR A BrandContainer.jsx`} color={brand.color} link={brand.slug} className="mb-5" />
                    </Col>
                  </Row>
                )}
              </div>
            )}
          </div>
        ))}
      </LazyLoad>
    );
  }
}

BrandContainer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  items: PropTypes.array.isRequired,
};

export default BrandContainer;
