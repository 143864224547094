import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  ListGroup, ListGroupItem, Media, NavLink,
} from 'reactstrap';
import Slider from 'react-slick';

function redirectPage(product) {
  location.href = "/"+product;
}

class MenuBrandCategory extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.state = {
      dropdownOpen: '',
    };
  }

  onMouseEnter(i) {
    this.setState({ dropdownOpen: i });
  }

  onMouseLeave() {
    this.setState({ dropdownOpen: '' });
  }

  toggle() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }

  render() {
    const { categories, color } = this.props;

    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 6,
      slidesToScroll: 1,
      autoplay: false,
      lazyLoad: true,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 4,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 3,
          },
        },
      ],
    };

    return (
      <div className="bg-white w-100 submenu text-center">
        <ListGroup horizontal className="mb-0 d-block w-100 bg-white ml-n3">
          <Container>
            {categories.map((category) => (
              <ListGroupItem
                key={category.name}
                className="rounded-0 border-0 d-inline-block text-black font-weight-bold"
                onMouseOver={() => this.onMouseEnter(category.name)}
                onMouseLeave={this.onMouseLeave}
                style={{ backgroundColor: (this.state.dropdownOpen === category.name) ? color : '' }}
              >
                {category.name}
              </ListGroupItem>
            ))}
          </Container>
        </ListGroup>
        {categories.map((category) => (
          <div
            key={category.name}
            style={{ visibility: (this.state.dropdownOpen === category.name) ? 'visible' : 'hidden' }}
            className="position-absolute w-100 bg-white submenu-carousel border-bottom"
            onMouseOver={() => this.onMouseEnter(category.name)}
            onMouseLeave={this.onMouseLeave}
          >
            <Slider {...settings} className='moto_slider'>
              {category.products
                .filter((product) => product.active)
                .map((product) => (
                <NavLink onClick={() => redirectPage(product.slug)} /*href={`${product.slug}`}*/ key={product.slug} className="px-2 py-0">
                  <Media object src={product.image_url || ''} alt={product.slug} className="w-100" width="100" height="100" />
                  <Media
                    tag="small"
                    className="w-100 text-black justify-content-center submenu-product-name text-uppercase mt-3 font-weight-bold"
                  >
                    {product.name}
                  </Media>
                </NavLink>
              ))}
            </Slider>
          </div>
        ))}
      </div>
    );
  }
}

MenuBrandCategory.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  categories: PropTypes.array.isRequired,
  color: PropTypes.string.isRequired,
};

export default MenuBrandCategory;
