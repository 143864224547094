import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Isotope from 'isotope-layout/js/isotope';
import {
  Col, Container, ListGroup, ListGroupItem, Media, NavLink, Row,
} from 'reactstrap';
// import LazyLoad from 'react-lazyload';

const GridFilter = ({
  title, itemKey, color, filters, items,
}) => {
  // store the isotope object in one state
  const [isotope, setIsotope] = useState(null);
  // store the filter keyword in another state
  const [filterKey, setFilterKey] = useState('*');

  // initialize an Isotope object with configs
  useEffect(() => {
    setIsotope(
      new Isotope('.grid', {
        itemSelector: '.grid-item',
        layoutMode: 'fitRows',
        percentPosition: true,
        resize: true,
        fitRows: {
          gutter: '.gutter-sizer',
        },
      }),
    );
  }, []);

  // handling filter key change
  useEffect(
    () => {
      if (isotope) {
        // eslint-disable-next-line no-unused-expressions
        filterKey === '*'
          ? isotope.arrange({ filter: '*' })
          : isotope.arrange({ filter: `.${filterKey}` });
      }
    },
    [isotope, filterKey],
  );

  function filter(element, value) {
    setFilterKey(value);
    $('.filter-item').css({ backgroundColor: 'transparent', color: '#000' });
    $(element).css({ backgroundColor: color, color: '#fff' });
  }

  return (
    <div className="d-flex flex-column align-items-center bg-white text-dark pb-5 px-2 mt-n1">
      <Media className="mt-0 mb-1 py-3" />
      <Media tag="h2" className="mt-2 mb-2 py-4">
        {/* Motos */}
        {/* <strong className="ml-2 text-uppercase font-weight-bold">{title}</strong> */}
        Descubre tu moto ideal
      </Media>
      <Media tag="h2" className="mt-0 mb-2 py-3">
        Elige tu categoría:
      </Media>
      {/* <Media className="mt-0 mb-1 py-3" /> */}
      <ListGroup horizontal className="mb-2 d-block text-center">
        <ListGroupItem
          onClick={(e) => filter(e.target, '*')}
          className="py-2 px-5 font-weight-bold filter-item rounded-0 border-0 d-inline-block"
          style={{ backgroundColor: color, color: '#fff' }}
        >
          TODAS
        </ListGroupItem>
        {filters.map((item) => (
          <ListGroupItem key={item} onClick={(e) => filter(e.target, item)} className="py-2 px-5 font-weight-bold filter-item rounded-0 border-0 d-inline-block">{item}</ListGroupItem>
        ))}
      </ListGroup>
      {/* <Media className="mt-3 mb-3 py-3" /> */}
      <Container>
        <Row className="grid pb-2">
          <div className="gutter-sizer" />
          {items.map((item) => (
            <Col xs="12" sm="6" md="4" lg="3" key={item.slug} className={`border-0 grid-item ${item.category.name}`}>
              <NavLink href={`/${item.slug}`} className="image">
                <div className="caption" />
                <Media src={item.picture_colors[0].picture.image_url || ''} className="w-100 " alt={item.name} width="100" height="100" />
                <Media tag="h5" className="justify-content-center mt-3 subtitle font-weight-bold">{item.category.name}</Media>
                <Media tag="h4" className="justify-content-center font-weight-black title">{item.name}</Media>
              </NavLink>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

GridFilter.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  filters: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  items: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  itemKey: PropTypes.string.isRequired,
};

export default GridFilter;
